import {
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { _STYLES } from "../../../utils/_style_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import { NumericFormat } from "react-number-format";

function GestionViagereRemuneration({
  register,
  errors,
  typeBiens,
  currentMandat,
  setCurrentMandat,
  refresh,
  disabledSign = false,
}) {
  const honoraireType = ["Forfait 40€", "Pourcentage 7.5%", "Forfait 98€"];

  return (
    <>
      <Grid
        container
        columnSpacing={1}
        rowSpacing={1}
        padding={4}
        sx={{ alignItems: "center" }}
      >
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Rente Viagère*
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <NumericFormat
              thousandSeparator=" "
              allowNegative={false}
              customInput={TextField}
              decimalScale={2}
              displayType="input"
              allowedDecimalSeparators={["."]}
              fullWidth
              label="Rente Viagere"
              size="small"
              id="renteViagere"
              disabled={currentMandat?.type === "avenant" || disabledSign}
              value={
                currentMandat?.renteViagere ? currentMandat.renteViagere : ""
              }
              onChange={(e) => {
                setCurrentMandat({
                  ...currentMandat,
                  renteViagere: e.target.value.replace(/ /g, ""),
                });
              }}
              onBlur={(e) => {
                editInstantRequest(
                  parseFloat(e.target.value.replace(/ /g, "")),
                  currentMandat["@id"],
                  "renteViagere"
                );

                refresh();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">€/mois</InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          {" "}
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            Type d'honoraires de gestion*
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <Select
              label="Honoraires de gestion"
              size="small"
              {...register("honoraire", { required: false })}
              id="honoraire"
              value={currentMandat?.honoraire ? currentMandat.honoraire : ""}
              onChange={(e) => {
                let m = editInstantRequest(
                  e.target.value,
                  currentMandat["@id"],
                  "honoraire"
                );

                m.then((m) => {
                  if (m["@context"] === "/api/contexts/Error") {
                    refresh();
                  } else {
                    setCurrentMandat(m);
                  }
                });
              }}
              input={<OutlinedInput label="Name" />}
            >
              {honoraireType.map((type, i) => (
                <MenuItem key={i} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
            {currentMandat && currentMandat?.honoraire?.includes("Forfait")
              ? "Montant"
              : "Pourcentage"}{" "}
            des Honoraires de gestion*
          </Typography>
        </Grid>

        <Grid item md={4} xs={12}>
          <FormControl fullWidth>
            <NumericFormat
              thousandSeparator=" "
              allowNegative={false}
              customInput={TextField}
              decimalScale={2}
              displayType="input"
              allowedDecimalSeparators={["."]}
              fullWidth
              label="Personnalisation des honoraires"
              size="small"
              id="minimumMensuel"
              disabled={currentMandat?.type === "avenant" || disabledSign}
              value={
                currentMandat?.minimumMensuel
                  ? currentMandat.minimumMensuel
                  : currentMandat?.honoraire === "Forfait 40€"
                  ? 40
                  : currentMandat?.honoraire === "Forfait 98€"
                  ? 98
                  : 7.5
              }
              onChange={(e) => {
                setCurrentMandat({
                  ...currentMandat,
                  minimumMensuel: parseFloat(e.target.value),
                });
              }}
              onBlur={(e) => {
                editInstantRequest(
                  parseFloat(e.target.value),
                  currentMandat["@id"],
                  "minimumMensuel"
                );

                refresh();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {currentMandat?.honoraire?.includes("Forfait")
                      ? "€ TTC"
                      : "% TTC"}
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default GestionViagereRemuneration;
