import { Grid, Typography } from "@mui/material";
import { _STYLES } from "../../utils/_style_constants";
import { RequestHelper } from "../../utils/requestHelper";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { _COLORS } from "../../utils/_constants";
import SignerDisplay from "../forms/SignerDisplay";
import DateSignDisplay from "../forms/DateSignDisplay";
import ActionSignature from "../forms/ActionSignature";

function EnvoiSignature() {
  const currentIdUrl = useParams();
  const requestHelper = new RequestHelper();
  const [currentMandat, setCurrentMandat] = useState(null);
  const [data, setData] = useState({});
  const [dateDisabled, setDateDisabled] = useState(false);

  const getMandat = () => {
    requestHelper.get(`/api/mandats/${currentIdUrl.id}`).then((response) => {
      setCurrentMandat(response.data);
    });
  };

  useEffect(() => {
    getMandat();
  }, []);

  useEffect(() => {
    for (const properties in data) {
      if (data[properties]?.signApi) {
        setDateDisabled(true);
      } else {
        setDateDisabled(false);
      }
    }
  }, [data]);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          border: "2px solid rgb(238, 238, 238)",
          borderRadius: "10px",
          gap: "1rem",
          padding: 4,
          margin: 1,
        }}
      >
        <Grid sx={{ width: "100%" }}></Grid>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
          }}
          item
          xs={11}
          md={11}
        >
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              color: _COLORS.primary,
              paddingBottom: 15,
            }}
          >
            Envoi en signature électronique :
          </Typography>
        </Grid>
        {/* Boxes */}
        <Grid sx={{ ..._STYLES.sxGridChild }}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: _COLORS.primary,
              padding: 15,
            }}
          >
            Signataires
          </Typography>

          {/* Add signataire info */}
          {currentMandat?.signataire && (
            <SignerDisplay
              index={1}
              obj={currentMandat.signataire}
              data={data}
              setData={setData}
            ></SignerDisplay>
          )}
          {currentMandat?.mandants?.map((mandant, index) => {
            return (
              <SignerDisplay
                index={index + 2}
                obj={mandant}
                data={data}
                setData={setData}
              />
            );
          })}
        </Grid>
        <DateSignDisplay
          dateDisabled={dateDisabled}
          currentMandat={currentMandat}
          setCurrentMandat={setCurrentMandat}
          requestHelper={requestHelper}
        ></DateSignDisplay>

        <ActionSignature
          data={data}
          requestHelper={requestHelper}
          currentMandat={currentMandat}
        ></ActionSignature>
      </Grid>
    </>
  );
}

export default EnvoiSignature;
