import axios from "axios";
import Swal from "sweetalert2";
import { _MESSAGES, _SWAL_ICONS } from "../utils/_constants";
import { getCookie } from "./cookieHelper";

/**
 * Cette classe permet de faire des requêtes HTTP
 *
 */
export class RequestHelper {
  constructor(token = null) {
    if (token === "" || token === null || token === undefined) {
      const tmp = getCookie("token");
      token = tmp ? tmp : null;
    }

    this.client = axios.create({
      baseURL: process.env.REACT_APP_apiUrl,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/ld+json",
        Authorization: token ? `Bearer ${token}` : "",
      },
      validateStatus: function (status) {
        return status >= 200 && status < 500; // default
      },
    });
    this.init();
  }

  init() {
    // Add interceptors
    this.client.interceptors.response.use(
      function (response) {
        // If invalid credentials
        if (response.status === 401) {
          Swal.fire({
            icon: _SWAL_ICONS.error,
            title: "Oops...",
            text: _MESSAGES.identifiants_invalides,
          }).then(() => {
            /*@TODO find a way to redirect*/
            window.location.href = "/";
          });
        }
        // If unautorized
        if (response.status === 403) {
          Swal.fire({
            icon: _SWAL_ICONS.error,
            title: "Oops...",
            text: _MESSAGES.unAuthorized,
          }).then(() => {
            /*@TODO find a way to redirect*/
            window.location.href = "/";
          });
        }
        // If not found
        if (response.status === 404) {
          Swal.fire({
            icon: _SWAL_ICONS.error,
            title: "Oops...",
            text: _MESSAGES.notFound,
          }).then(() => {
            /*@TODO find a way to redirect*/
          });
        }
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
      },
      function (error) {
        console.log(error);
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }

  /**
   *
   * @param {string} method
   * @param {string} url
   * @param {object} params
   * @param {object} options
   * @returns
   */
  doRequest(method, url, params, options = {}) {
    switch (method) {
      case "GET":
        return this.get(url, params, options);
      case "POST":
        return this.post(url, params, options);
      case "PUT":
        return this.put(url, params, options);
      case "POSTFILE":
        return this.postFile(url, params, options);
      case "DELETE":
        return this.delete(url, params, options);
    }
  }

  /**
   *
   * @param {string} url
   * @param {Object} queryparams
   * @param {Object} options
   * @returns
   */
  get(url, queryparams, options = {}) {
    return this.client.get(url, { params: queryparams });
  }

  /**
   *
   * @param {string} url
   * @param {Object} queryparams
   * @param {Object} options
   * @returns
   */
  post(url, body, options = {}) {
    return this.client.post(url, body);
  }

  /**
   *
   * @param {string} url
   * @param {Object} body
   * @param {Object} options
   * @returns
   */
  put(url, body, options = {}) {
    return this.client.put(url, body);
  }

  /**
   *
   * @param {*} url
   * @param {*} body
   * @param {*} options
   * @returns
   */
  postFile(url, body, options = {}) {
    this.client.defaults.headers["Content-Type"] = "multipart/form-data";

    return this.client.post(url, body);
  }

  /**
   *
   * @param {string} url
   * @param {Object} queryparams
   * @param {Object} options
   * @returns
   */
  delete(url, queryparams, options = {}) {
    return this.client.delete(url, { params: queryparams });
  }
}
