import { Box, Button, Grid, Typography } from "@mui/material";
import {
  _ACTIONS,
  _COLORS,
  _MESSAGES,
  _STATUTCOLORS,
  _SWAL_ICONS,
} from "../../utils/_constants";
import MandatsFilterForm from "../forms/MandatFilerForm";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { btn, _STYLES } from "../../utils/_style_constants.js";
import { useContext, useEffect, useRef, useState } from "react";
import { DataGrid, frFR } from "@mui/x-data-grid";
import { RequestHelper } from "../../utils/requestHelper";
import { createToast } from "../../utils/toast";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { UserContext } from "../contexts/UserContext";
import moment from "moment";
import IpIsoleeForm from "../forms/IpIsoleeForm";
import BasicModal from "../helpers/BasicModal";
import MandatAction from "../helpers/MandatAction";
import { capitalizeFirstLetter } from "../../utils/helper";
import _ from "lodash";

/**
/**
 * This file contains the ListeMandats component, which displays a table of mandats and a filter form.
 * 
 * @module ListMandats
 * @returns {JSX.Element} The ListeMandats component.
 */
function ListeMandats(props) {
  const [rowsMandats, setRowsMandats] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const gridRef = useRef(null);
  const { user } = useContext(UserContext);
  const [agences, setAgences] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const requestHelper = new RequestHelper();

  let navigate = useNavigate();

  useEffect(() => {
    if (!_.isEmpty(user)) {
      let agencies = {};
      if (user.profil === "SUSER" || user.profil === "USER") {
        user.agences.forEach((element) => {
          agencies[element.nom] = element.id;
        });
      } else {
        requestHelper.get("/api/agences").then((response) => {
          if (response.status === 200) {
            response.data["hydra:member"].forEach((element) => {
              agencies[element.nom] = element.id;
            });
          }
        });
      }
      setAgences(agencies);
    }
  }, [user]);

  /**
   * Handler for showing/hiding the modal for adding/editing an agency.
   */
  function showModalHandler() {
    setShowModal(!showModal);
  }

  const refreshGrid = () => {
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    refreshGrid();
  }, [props.clickedChoice, user, agences]);

  const createAgenceOptions = (agences) => {
    let options = {};
    for (const p in agences) {
      options[agences[p]] = p;
    }

    return options;
  };

  /**
   * Columns for the DataGrid component.
   */
  const columnsListMandats = [
    {
      field: "numeroDossier",
      headerName: "# Dossier",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return (
          <strong>
            <span style={{ color: _COLORS.success }}>
              {params.row.numeroDossier ? params.row.numeroDossier : "N/A"}
            </span>
          </strong>
        );
      },
    },
    {
      field: "numeroMandat",
      headerName: "# Mandat",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return (
          <strong>
            <span style={{ color: _COLORS.success }}>
              {params.row.numero
                ? params?.row?.agence?.numeroAgence +
                  (params?.row?.typeVente?.nomTechnique
                    ? params?.row?.typeVente?.nomTechnique
                    : "RE") +
                  params?.row?.numero
                : "N/A"}
            </span>
          </strong>
        );
      },
    },
    {
      field: "type",
      headerName: "Type de mandat",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return (
          <Typography sx={{ fontWeight: "bold" }}>
            {capitalizeFirstLetter(params.row.type)}
          </Typography>
        );
      },
    },
    {
      field: "agence",
      headerName: "Agence",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return (
          <Typography sx={{ fontWeight: "bold" }}>
            {capitalizeFirstLetter(params.row.agence.nom)}
          </Typography>
        );
      },
    },
    {
      field: "mandants",
      headerName: "Mandants",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return (
          <strong>
            {params.row.mandants
              ? params.row.mandants.map((mandant) => {
                  if (mandant.nom === null) return "";

                  return mandant.nom + " " + mandant.prenom + ", ";
                })
              : "N/A"}
          </strong>
        );
      },
    },
    {
      field: "statut.nom",
      headerName: "Statut",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return (
          <strong>
            <span
              style={{
                color: _STATUTCOLORS[params.row.statut.nomTechnique],
              }}
            >
              {params.row.statut ? params.row.statut.nom : "N/A"}
            </span>
          </strong>
        );
      },
    },
    {
      field: "typeVente.id",
      headerName: "Type de vente",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return (
          <strong>
            <span>
              {params.row.typeVente ? params.row.typeVente.nom : "N/A"}
            </span>
          </strong>
        );
      },
    },
    {
      field: "exclusif",
      headerName: "Exclusif",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        let status =
          params.row.exclusif === null
            ? "Non renseigné"
            : params.row.exclusif
            ? "Oui"
            : "Non";

        return <strong>{status}</strong>;
      },
    },
    {
      field: "adresseBien",
      headerName: "Adresse du bien",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return (
          <strong>{params.row.bien ? params.row.bien.adresse : "N/A"}</strong>
        );
      },
    },
    {
      field: "dateCreation",
      headerName: "Date de création",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return (
          <strong>
            {params.row.dateCreation
              ? moment(params.row.dateCreation).format("DD/MM/YYYY")
              : "N/A"}
          </strong>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => (
        <strong>
          <MandatAction
            params={params}
            refreshGrid={refreshGrid}
          ></MandatAction>
        </strong>
      ),
    },
  ];

  // Création d'un mandat
  function createMandatBddAndEdit(agence, mode = "vente") {
    if (agence) {
      requestHelper
        .doRequest(_ACTIONS.POST, "/api/mandats", {
          bien: {},
          type: mode,
          ventilations: [{}, {}],
          agence: "/api/agences/" + agence,
        })
        .then((response) => {
          if (response.status === 201) {
            createToast(_SWAL_ICONS.success, _MESSAGES.mandat_create_success);
            navigate(`/mandat-${mode}/` + response.data["id"]);
          } else {
            createToast(_SWAL_ICONS.error, _MESSAGES.mandat_create_error);
          }
        });
    }
  }

  /** Creation d'un mandant de vente vide **/
  const handleCreateMandatVente = () => {
    if (user.agences.length === 1) {
      createMandatBddAndEdit(user.agences[0].id);
    } else {
      (async () => {
        const { value: agence } = await Swal.fire({
          title: "Veuillez sélectionner une agence",
          input: "select",
          inputOptions: createAgenceOptions(agences),
          inputPlaceholder: "Sélectionner une agence",
          showCancelButton: true,
          cancelButtonText: "Annuler",
        });
        createMandatBddAndEdit(agence);
      })();
    }
  };

  /** Création d'un mandat de recherche */
  const handleCreateMandatRecherche = () => {
    if (user.agences.length === 1) {
      createMandatBddAndEdit(user.agences[0].id, "recherche");
    } else {
      (async () => {
        const { value: agence } = await Swal.fire({
          title: "Veuillez sélectionner une agence",
          input: "select",
          inputOptions: createAgenceOptions(agences),
          inputPlaceholder: "Sélectionner une agence",
          showCancelButton: true,
          cancelButtonText: "Annuler",
        });
        createMandatBddAndEdit(agence, "recherche");
      })();
    }
  };

  return (
    <Box sx={_STYLES.mainContainer}>
      <Grid sx={_STYLES.filterFormGrid} container>
        {/** Title */}
        <Grid item xl={3}>
          <Typography
            variant="h5"
            style={{
              padding: "15px",
              fontWeight: "bold",
              color: _COLORS.primary,
            }}
          >
            Recherche mandat
          </Typography>
        </Grid>
        <Grid item xl={9}></Grid>
        {/* Filter Form */}
        <Grid item xl={12}>
          <MandatsFilterForm
            gridRef={gridRef}
            clickedChoice={props.clickedChoice}
            setRowsMandats={setRowsMandats}
            refresh={refresh}
            agences={agences}
            user={user}
          />
        </Grid>
      </Grid>
      {/* Modal */}
      <BasicModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="Création d'une IP isolée"
        content={
          <IpIsoleeForm
            showModal={showModal}
            setShowModal={setShowModal}
          ></IpIsoleeForm>
        }
      />

      {/* Spacer */}
      <Box sx={{ height: "2%" }}></Box>

      {/* Table */}
      <Box sx={_STYLES.secondaryContainer}>
        <Grid sx={{ width: "100%" }} container rowSpacing={1}>
          <Grid item xl={3}>
            <Typography
              variant="h5"
              style={{
                padding: "15px",
                fontWeight: "bold",
                color: _COLORS.primary,
              }}
            >
              Liste des mandats
            </Typography>
          </Grid>
          <Grid item xl={3}>
            <Button
              sx={{
                ...btn(_COLORS.light, _COLORS.secondary),
              }}
              variant="contained"
              onClick={showModalHandler}
              startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
            >
              Créer une IP isolée
            </Button>
          </Grid>
          <Grid item xl={3}>
            <Button
              sx={{
                ...btn(_COLORS.secondary, _COLORS.success),
              }}
              variant="contained"
              startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
              onClick={handleCreateMandatVente}
            >
              Créer un mandat de vente
            </Button>
          </Grid>
          <Grid item xl={3}>
            <Button
              sx={{
                ...btn(_COLORS.primaryLight, _COLORS.primaryUltraLight),
              }}
              variant="contained"
              startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
              onClick={handleCreateMandatRecherche}
            >
              Créer un mandat de recherche
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box sx={_STYLES.dataGridContainer}>
              <DataGrid
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                sx={{ height: "500px" }}
                rows={rowsMandats}
                columns={columnsListMandats}
                disableSelectionOnClick={true}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                      page: 0,
                    },
                  },
                }}
                pageSizeOptions={[10, 25]}
                ref={gridRef}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ListeMandats;
