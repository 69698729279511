import { Circles } from "react-loader-spinner";

export function LoadingComponent(loading) {
  return (
    <>
      {loading.loading && (
        <div className="container-spinner">
          <Circles
            height="80"
            width="80"
            color="#fc62b2"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="spinner"
            visible={loading.loading}
          />
        </div>
      )}
    </>
  );
}

export default LoadingComponent;
