export function getAgencesForApi(user, type = "agence") {
  let agences = "";
  if (user.profil === "USER" || user.profil === "SUSER") {
    user.agences.map((agenceId) => {
      if (type == "agence") agences = agences + "agence[]=" + agenceId.id + "&";
      if (type == "agences")
        agences = agences + "agences[]=" + agenceId.id + "&";
      if (type == "id") agences = agences + "id[]=" + agenceId.id + "&";
    });
  }
  return agences;
}

export const toolbarCkeditor = {
  toolbar: {
    items: [
      "undo",
      "heading",
      "bold",
      "italic",
      "numberedList",
      "bulletedList",
      "blockQuote",
      "insertTable",
    ],
  },
};
