import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { _COLORS } from "../../utils/_constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  overflow: "auto",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

/**
 * Template de modale basique
 *
 *
 * @returns
 */
export default function BasicModal({
  showModal,
  setShowModal,
  title,
  content,
}) {
  function handleClose() {
    setShowModal(false);
  }

  return (
    <div>
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: "0",
              right: "0",
              mt: "10px",
              mr: "10px",
            }}
            onClick={handleClose}
          ></CloseIcon>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h3 style={{ color: _COLORS.primary, fontWeight: "bold" }}>
              {title}
            </h3>
          </Typography>
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            {content}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
