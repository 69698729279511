import CustomRouter from "./Router";
import "./App.css";
import GlobalContextProvider from "./components/contexts/GlobalContext";
import UserContextProvider from "./components/contexts/UserContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/fr";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Theme = createTheme({
  typography: {
    fontFamily: "Source Sans Pro",
  },
  button: {
    fontFamily: "Source Sans Pro",
  },
});

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <UserContextProvider>
        <GlobalContextProvider>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
            <CustomRouter />
          </LocalizationProvider>
        </GlobalContextProvider>
      </UserContextProvider>
    </ThemeProvider>
  );
}

export default App;
