import Swal from "sweetalert2";
import { _SWAL_BTN, _SWAL_ICONS } from "./_constants";

/**
 * Use to create confirm pop-up
 *
 * Exemple :
 *
 * createConfirm("Voulez vous supprimer cette aide")
 *      .fire({})
 *      .then(result => {
 *          if (result.isConfirmed){
 *              // Traitement
 *          }
 *      })
 *
 * @param question
 */
export function createConfirm(question) {
  let option = {
    title: question,
    icon: _SWAL_ICONS.question,
    showCancelButton: true,
    confirmButtonText: _SWAL_BTN.Confirmer,
    cancelButtonText: _SWAL_BTN.Annuler,
  };

  return Swal.mixin(option);
}
