import React from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import { useNavigate } from "react-router";
import { UserContext } from "../contexts/UserContext";

// @todo aller chercher les menu via API en fonction du USER

function ListMenu() {
  const { open, handleDrawer, handleDrawerOpen } =
    React.useContext(GlobalContext);
  const navigate = useNavigate();
  const { user } = React.useContext(UserContext);
  let arrayListMenu = [];
  switch (user.profil) {
    case "SADMIN":
    case "ADMIN":
    case "SUSER":
      arrayListMenu = [
        {
          id: 1,
          title: "Accueil",
          icon: <HomeIcon />,
          path: "/nav",
        },
        {
          id: 2,
          title: "Agences",
          icon: <ApartmentIcon />,
          path: "/agences",
        },
        {
          id: 3,
          title: "Utilisateurs",
          icon: <AccountCircleIcon />,
          path: "/users",
        },
        {
          id: 4,
          title: "Liste des demandes de signature",
          icon: <ChecklistRtlIcon />,
          path: "/list-sign-request",
        },
      ];
      break;
    case "USER":
    default:
      arrayListMenu = [
        {
          id: 1,
          title: "Accueil",
          icon: <HomeIcon />,
          path: "/nav",
        },
        {
          id: 2,
          title: "Agences",
          icon: <ApartmentIcon />,
          path: "/agences",
        },
        {
          id: 3,
          title: "Utilisateurs",
          icon: <AccountCircleIcon />,
          path: "/users",
        },
        {
          id: 4,
          title: "Liste des demandes de signature",
          icon: <ChecklistRtlIcon />,
          path: "/list-sign-request",
        },
      ];
      break;
  }
  return (
    <>
      {arrayListMenu.map((item, index) => {
        return (
          <ListItem key={item.id} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                navigate(item.path);
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                sx={{
                  opacity: open ? 1 : 0,
                  color: "white",
                  whiteSpace: open ? "normal" : "nowrap",
                }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </>
  );
}

export default ListMenu;
