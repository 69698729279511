import { Box, TextField, setRef } from "@mui/material";
import { _STYLES } from "../../utils/_style_constants";
import { editInstantRequest } from "../../utils/formHelper";
import { _ACTIONS } from "../../utils/_constants";
import { createToast } from "../../utils/toast";

function LifeConsentApiForm({
  agence,
  setAgence,
  refresh,
  setRefresh,
  requestHelper,
}) {
  function updateLifeConfig(field, value, lifeConsentConfig) {
    requestHelper
      .doRequest(_ACTIONS.POST, "/api/maj-life-consent-config", {
        accountName: lifeConsentConfig.accountName,
        accountPassword: lifeConsentConfig.accountPassword,
        accountApiKey: lifeConsentConfig.accountApiKey,
        [field]: value,
        agenceId: agence.id,
      })
      .then((response) => {
        setRefresh(refresh + 1);
        setAgence({
          ...agence,
          lifeConsentAvaible: response.data.success,
          lifeConsentConfig: {
            ...lifeConsentConfig,
            [field]: value,
          },
        });
        createToast(
          response.data.success ? "success" : "error",
          response.data.message
        );
      });
  }

  return (
    <div>
      <Box sx={_STYLES.boxInputRow}>
        <TextField
          sx={_STYLES.basicInput}
          size="small"
          id="accountName"
          value={
            agence?.lifeConsentConfig?.accountName
              ? agence.lifeConsentConfig.accountName
              : ""
          }
          label="Nom du compte LifeConsent"
          onChange={(e) => {
            setAgence({
              ...agence,
              lifeConsentConfig: {
                ...agence.lifeConsentConfig,
                accountName: e.target.value,
              },
            });
          }}
          onBlur={(e) => {
            updateLifeConfig(
              "accountName",
              e.target.value,
              agence.lifeConsentConfig
            );
          }}
        ></TextField>
      </Box>
      <Box sx={_STYLES.boxInputRow}>
        <TextField
          type="password"
          sx={_STYLES.basicInput}
          size="small"
          id="accountPassword"
          value={
            agence?.lifeConsentConfig?.accountPassword
              ? agence.lifeConsentConfig.accountPassword
              : ""
          }
          onChange={(e) => {
            setAgence({
              ...agence,
              lifeConsentConfig: {
                ...agence.lifeConsentConfig,
                accountPassword: e.target.value,
              },
            });
          }}
          onBlur={(e) => {
            updateLifeConfig(
              "accountPassword",
              e.target.value,
              agence.lifeConsentConfig
            );
          }}
          label="Mot de passe du compte LifeConsent"
        ></TextField>
      </Box>
      <Box sx={_STYLES.boxInputRow}>
        <TextField
          sx={_STYLES.basicInput}
          size="small"
          id="accountApiKey"
          value={
            agence?.lifeConsentConfig?.accountApiKey
              ? agence.lifeConsentConfig.accountApiKey
              : ""
          }
          onChange={(e) => {
            setAgence({
              ...agence,
              lifeConsentConfig: {
                ...agence.lifeConsentConfig,
                accountApiKey: e.target.value,
              },
            });
          }}
          onBlur={(e) => {
            updateLifeConfig(
              "accountApiKey",
              e.target.value,
              agence.lifeConsentConfig
            );
          }}
          label="Clé API du compte LifeConsent"
        ></TextField>
      </Box>
    </div>
  );
}

export default LifeConsentApiForm;
