import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import {
  _ACTIONS,
  _COLORS,
  _FORMATS,
  _GESTION_VIAGERE,
  _VENTE_TRADITIONNELLE,
} from "../../utils/_constants";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { btn } from "../../utils/_style_constants";
import { RequestHelper } from "../../utils/requestHelper";
import {
  buildOptionsFromResponse,
  getQueryParamsFromFormData,
} from "../../utils/formHelper";
import { UserContext } from "../contexts/UserContext";
import _, { isEmpty, isUndefined } from "lodash";
import { getAgencesForApi } from "../helpers/Utils";

export const excluOpt = [
  { value: "", text: "Tous" },
  { value: "0", text: "Non" },
  { value: "1", text: "Oui" },
];

/**
 * @desc The periodeOpt array contains the options for the periode select.
 * Each option has a value and a text to display.
 * @type {Array}
 */
export const periodeOpt = [
  { value: "", text: "Aucune période" },
  { value: "currentYear", text: "Cette année" },
  { value: "lastYear", text: "L'année dernière" },
  { value: "currentMonth", text: "Ce mois-ci" },
  { value: "lastMonth", text: "Le mois dernier" },
  { value: "last3Month", text: "Les 3 derniers mois" },
  { value: "last6Month", text: "Les 6 derniers mois" },
];

function MandatsFilterForm(props) {
  const { control, handleSubmit, register, setValue } = useForm();
  const [listMandatsSearch, setListMandatsSearch] = useState("");
  const [statuts, setStatuts] = useState([]);
  const [statutSelected, setStatutSelected] = useState("");
  const [typeVente, setTypeVente] = useState([]);
  const [typeVenteSelected, setTypeVenteSelected] = useState("");
  const [exclusifSelected, setExclusifSelected] = useState("");
  const [periodeSelected, setPeriodeSelected] = useState("");
  const [agenceSelected, setAgenceSelected] = useState("");
  const [agences, setAgences] = useState(props.agences);
  const [dateDebValue, setDateDebValue] = useState(null);
  const [dateFinValue, setDateFinValue] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const { token, user } = React.useContext(UserContext);
  const requestHelper = new RequestHelper(token);
  const formRef = React.useRef(null);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      let agencies = {};
      if (user.profil === "SUSER" || user.profil === "USER") {
        user.agences.forEach((element) => {
          agencies[element.nom] = element.id;
        });
      } else {
        requestHelper.get("/api/agences").then((response) => {
          if (response.status === 200) {
            response.data["hydra:member"].forEach((element) => {
              agencies[element.nom] = element.id;
            });
          }
        });
      }
      setAgences(agencies);
    }
  }, [user]);

  useEffect(() => getStatus(), []);
  useEffect(() => getTypeVente(), []);
  useEffect(() => handleClickChoice(), [props.clickedChoice]);

  const handleDateDebChange = (date) => {
    setDateDebValue(date);
    setValue("dateCreationAF", date.format(_FORMATS.dateTime));
  };

  const handleDateFinChange = (date) => {
    setDateFinValue(date);
    setValue("dateCreationBF", date.format(_FORMATS.dateTime));
  };

  /**
   * @desc Gets the statuts from the API and sets the statuts state.
   */
  function getStatus() {
    requestHelper.doRequest(_ACTIONS.GET, "/api/statuts").then((response) => {
      if (response.status === 200) {
        setStatuts(buildOptionsFromResponse(response.data, "@id", "nom"));
      }
    });
  }

  /**
   * @desc Gets the type de vente from the API and sets the typeVente state.
   */
  function getTypeVente() {
    requestHelper.get("/api/type_ventes").then((response) => {
      /** Filtre Pour gestion Viagére à mettre uniquement pour admin et sadmin */
      let arrayList = response.data["hydra:member"];
      let finalArrayList = [];
      arrayList.forEach((item) => {
        if (user.profil === "SUSER" || user.profil === "USER") {
          if (
            item.nom !== _GESTION_VIAGERE &&
            item.nom !== _VENTE_TRADITIONNELLE
          ) {
            finalArrayList.push(item);
          }
        } else {
          finalArrayList.push(item);
        }
      });
      setTypeVente(
        finalArrayList.map((item) => ({
          value: item["@id"],
          label: item["nom"],
        }))
      );
    });
  }

  /**
   * @desc Sets the statutSelected state to the clickedChoice value.
   */
  function handleClickChoice() {
    statuts.map((statut) => {
      if (statut.label === props.clickedChoice) {
        setStatutSelected(statut.value);
        setValue("statut", statut.value);
      }
    });
    /** Scroll to the grid */
    props.gridRef?.current?.scrollIntoView({ behavior: "smooth" });
  }

  /**
   * A function that handles change in period selection.
   *
   * @param {Event} e - The event object containing the target value.
   */
  const handlePeriodeChange = (e) => {
    setPeriodeSelected(e.target.value);
    setDisabled(true);
    let debVal;
    let finVal;
    switch (e.target.value) {
      case "currentYear":
        debVal = moment().startOf("year");
        finVal = moment().endOf("year");
        break;
      case "lastYear":
        debVal = moment().subtract(1, "year").startOf("year");
        finVal = moment().subtract(1, "year").endOf("year");
        break;
      case "currentMonth":
        debVal = moment().startOf("month");
        finVal = moment().endOf("month");
        break;
      case "lastMonth":
        debVal = moment().subtract(1, "month").startOf("month");
        finVal = moment().subtract(1, "month").endOf("month");
        break;
      case "last3Month":
        debVal = moment().subtract(3, "month").startOf("month");
        finVal = moment().endOf("month");
        break;
      case "last6Month":
        debVal = moment().subtract(6, "month").startOf("month");
        finVal = moment().endOf("month");
        break;
      default:
        setDisabled(false);
    }
    if (e.target.value === "") {
      debVal = null;
      finVal = null;
      setDateDebValue(debVal);
      setDateFinValue(finVal);
      setValue("dateCreationAF", "");
      setValue("dateCreationBF", "");

      return;
    }
    setDateDebValue(debVal);
    setDateFinValue(finVal);
    setValue("dateCreationAF", debVal.format(_FORMATS.dateTime));
    setValue("dateCreationBF", finVal.format(_FORMATS.dateTime));
  };

  const onSearch = (formData) => {
    const params = getQueryParamsFromFormData(formData);
    const agenceApiSearch = getAgencesForApi(user);

    if (user.profil == "SUSER" || user.profil == "USER") {
      params["agence[]"] = Object.values(props.agences);
      params["order[dateCreation]"] = "desc";
    }

    if (!isUndefined(formData.agence) && !isEmpty(formData.agence)) {
      params["agence[]"] = formData.agence;
    }
    if (!_.isEmpty(user)) {
      requestHelper
        .doRequest(
          _ACTIONS.GET,
          "/api/mandats?groups[]=mandat_list:get&order[dateCreation]=desc" +
            agenceApiSearch,
          params
        )
        .then((response) => {
          if (
            response.status === 200 &&
            response.data["hydra:member"].length > 0
          ) {
            props.setRowsMandats(response.data["hydra:member"]);
          }
        });
    }
  };

  useEffect(() => {
    if (user) {
      onSearch({});
    }
  }, [user]);

  useEffect(() => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  }, [props.refresh]);

  return (
    <form
      ref={formRef}
      action=""
      onSubmit={handleSubmit(onSearch)}
      style={{ width: "100%" }}
    >
      <Grid rowSpacing={1} container>
        <Grid item xl={2}>
          <TextField
            sx={{ width: "90%" }}
            size="small"
            defaultValue={listMandatsSearch}
            onChange={(e) => setListMandatsSearch(e.target.value)}
            placeholder="Nom prenom mandants, numéro, ville"
            {...register("listMandatsSearch", { required: false })}
            name="listMandatsSearch"
          ></TextField>
        </Grid>
        <Grid item xl={2}>
          <FormControl sx={{ width: "90%" }}>
            <InputLabel size="small">Statut</InputLabel>
            <Select
              label="Statut"
              size="small"
              {...register("statut", { required: false })}
              id="statut"
              value={statutSelected}
              onChange={(e) => {
                setStatutSelected(e.target.value);
              }}
              input={<OutlinedInput label="Name" />}
            >
              <MenuItem value="">Tous</MenuItem>
              {statuts.map((statut, i) => (
                <MenuItem key={i} value={statut.value}>
                  {statut.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={2}>
          <FormControl sx={{ width: "90%" }}>
            <InputLabel size="small">Type de Vente</InputLabel>
            <Select
              label="Type de vente"
              size="small"
              {...register("typeVente", { required: false })}
              id="typeVente"
              value={typeVenteSelected}
              onChange={(e) => {
                setTypeVenteSelected(e.target.value);
                setValue("typeVente", e.target.value);
              }}
              input={<OutlinedInput label="Name" />}
            >
              <MenuItem value="">Tous</MenuItem>
              {typeVente.map((type, i) => (
                <MenuItem key={i} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={2}>
          <FormControl sx={{ width: "90%" }}>
            <InputLabel size="small">Exclusif</InputLabel>
            <Select
              label="Exclusif"
              size="small"
              {...register("exclusif", { required: false })}
              id="exclusif"
              value={exclusifSelected}
              onChange={(e) => {
                setExclusifSelected(e.target.value);
              }}
              input={<OutlinedInput label="Name" />}
            >
              {excluOpt.map((exclusif, i) => (
                <MenuItem key={i} value={exclusif.value}>
                  {exclusif.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={3}>
          <FormControl sx={{ width: "90%" }}>
            <InputLabel size="small">Agences</InputLabel>
            <Select
              label="Agences"
              size="small"
              {...register("agence", { required: false })}
              id="agence"
              value={agenceSelected}
              onChange={(e) => {
                setAgenceSelected(e.target.value);
              }}
              input={<OutlinedInput label="Name" />}
            >
              <MenuItem key={""} value={""}>
                {"Tous"}
              </MenuItem>
              {Object.entries(agences).map(([key, value]) => (
                <MenuItem key={value} value={value}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={2}>
          <FormControl sx={{ width: "90%" }}>
            <InputLabel size="small">Période</InputLabel>
            <Select
              label="Période"
              size="small"
              id="periode"
              value={periodeSelected}
              input={<OutlinedInput label="Name" />}
              onChange={handlePeriodeChange}
              MenuProps={""}
            >
              {periodeOpt.map((periode, i) => (
                <MenuItem key={i} value={periode.value}>
                  {periode.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={2}>
          <Controller
            name="dateCreationAF"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <DatePicker
                sx={{ width: "90%" }}
                label="Date de début"
                size="small"
                format="DD/MM/YYYY"
                value={dateDebValue}
                disabled={disabled}
                onChange={handleDateDebChange}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xl={2}>
          <Controller
            name="dateCreationBF"
            control={control}
            defaultValue={null}
            value={dateFinValue}
            render={({ field }) => (
              <DatePicker
                sx={{ width: "90%" }}
                label="Date de Fin"
                size="small"
                format="DD/MM/YYYY"
                value={dateFinValue}
                disabled={disabled}
                onChange={handleDateFinChange}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xl={2}></Grid>
        <Grid item xl={2}>
          <Button
            sx={{ ...btn(_COLORS.primary, _COLORS.primaryLight), width: "85%" }}
            variant="Rechercher"
            type="submit"
          >
            Rechercher
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default MandatsFilterForm;
