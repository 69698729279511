import { Grid, Typography } from "@mui/material";
import { _COLORS, _VIAGER_MIXTE } from "../../../utils/_constants";
import { _STYLES } from "../../../utils/_style_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import _ from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toolbarCkeditor } from "../../helpers/Utils";
import MouseOverPopover from "../MouseOverPopover";
import HelpIcon from "@mui/icons-material/Help";

function ValorisationPrestations({
  register,
  errors,
  typeBiens,
  currentMandat,
  setCurrentMandat,
  refresh,
  disabledSign = false,
}) {
  return (
    <>
      <Grid
        fullWidth
        container
        columnSpacing={1}
        rowSpacing={1}
        padding={4}
        sx={{ alignItems: "center" }}
      >
        <Grid item xs={12} display={"flex"} gap={2} alignItems={"baseline"}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: _COLORS.primary,
            }}
          >
            Valorisation des prestations retenues
          </Typography>
          <MouseOverPopover
            sx={{
              marginLeft: "10px",
            }}
            title={<HelpIcon />}
            popoverText="  Lister précisément la nature des prestations, avec la possibilité, le cas échéant, d'en évaluer le coût y afférant. "
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CKEditor
            editor={ClassicEditor}
            config={toolbarCkeditor}
            label="Valorisation des prestations retenues"
            disabled={currentMandat?.type === "avenant" || disabledSign}
            data={
              currentMandat?.valorisationPrestationsRetenues
                ? currentMandat?.valorisationPrestationsRetenues
                : ""
            }
            onBlur={(event, editor) => {
              const data = editor.getData();
              let m = editInstantRequest(
                data,
                currentMandat["@id"],
                "valorisationPrestationsRetenues"
              );

              m.then((m) => {
                if (m["@context"] === "/api/contexts/Error") {
                  refresh();
                } else {
                  setCurrentMandat(m);
                }
              });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ValorisationPrestations;
