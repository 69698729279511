import { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { _STYLES, btn } from "../../utils/_style_constants";
import {
  _ACTIONS,
  _COLORS,
  _MESSAGES,
  _SWAL_ICONS,
} from "../../utils/_constants";
import { createConfirm } from "../../utils/confirm";
import { createToast } from "../../utils/toast";
import { useNavigate } from "react-router";
import _ from "lodash";
import Swal from "sweetalert2";
import { swalErrorsLisHtmlBuilder } from "../../utils/swalHtmlBuilder";
import { downloadFile } from "../../utils/helper";
import LoadingComponent from "../helpers/Loading";

function ActionSignature({ currentMandat, requestHelper, data }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [toSign, setToSign] = useState(false);

  const apercuAvantSign = () => {
    /** Create Apercu PDF with watermark  */
    if (_.isEmpty(currentMandat)) {
      createToast(
        _SWAL_ICONS.warning,
        "Erreur redirection vers la liste des mandats"
      ).then(navigate("/mandats"));
    } else {
      requestHelper
        .doRequest(_ACTIONS.POST, "/api/apercu-mandat", currentMandat)
        .then((response) => {
          if (response.file === null) {
            console.log("error");
          }
          if (
            response.status === 200 &&
            response.data?.errorsFields?.length > 0
          ) {
            Swal.fire({
              icon: _SWAL_ICONS.warning,
              title: _MESSAGES.warning,
              width: "45%",
              html: swalErrorsLisHtmlBuilder(response.data.errorsFields),
            });
          } else if (response.status === 200 && response.data?.file) {
            downloadFile("apercu.pdf", response.data.file);
          }
        });
    }
  };

  /**
   * Check if all data are filled for sign via API.
   *
   * @param {*} data
   * @returns
   */
  const checkData = (data) => {
    for (const [key, item] of Object.entries(data))
      if (item.signApi) {
        if (
          !item.email ||
          !item.portable ||
          !item.email.includes("@") ||
          item.portable.length < 10
        ) {
          return false;
        }
      }

    return true;
  };

  useEffect(() => {
    if (toSign) {
      envoiEnSign();
      setToSign(false);
    }
  }, [toSign]);

  const envoiEnSign = () => {
    // Pass the status to en cours de signature.
    // Generate final PDF
    // Create Sign Request to the API.
    // Redirect to the list of Requests.
    //@Todo check if sign via API or not
    if (checkData(data)) {
      createConfirm("Etes-vous sur de vouloir envoyer ce mandat en signature ?")
        .fire()
        .then((result) => {
          if (result.isConfirmed) {
            let finalData = {
              signForm: data,
              id: currentMandat["@id"],
            };
            setLoading(true);
            requestHelper
              .doRequest(_ACTIONS.POST, "/api/go-to-sign", finalData)
              .then((res) => {
                setLoading(false);
                if (res.data.success) {
                  createToast(
                    _SWAL_ICONS.success,
                    _MESSAGES.sign_request_success
                  ).then(() => {
                    navigate("/list-sign-request");
                  });
                } else {
                  createToast(_SWAL_ICONS.error, res.data.message).then();
                }
              });
          }
        });
    } else {
      createToast(_SWAL_ICONS.error, _MESSAGES.form_error);
    }
  };

  return (
    <>
      <LoadingComponent loading={loading} />
      <Grid sx={{ ..._STYLES.sxGridChild }}>
        <Typography
          variant="h5"
          style={{
            fontWeight: "bold",
            color: _COLORS.primary,
            padding: 15,
            display: "flex",
            flexDirection: "row",
          }}
        >
          Mandat
        </Typography>
        <Grid
          container
          display={"flex"}
          sx={{ alignItems: "center", paddingLeft: 25, paddingBottom: 5 }}
        >
          <Grid item md={6}>
            <Button
              sx={btn(_COLORS.success, _COLORS.secondary, "60%")}
              variant="contained"
              onClick={apercuAvantSign}
            >
              Apercu avant envoi
            </Button>
          </Grid>
          <Grid item md={6}>
            <Button
              sx={btn(_COLORS.primary, _COLORS.secondary, "60%")}
              variant="contained"
              disabled={currentMandat?.apiSignProcess}
              onClick={() => setToSign(true)}
            >
              Commencer la signature electronique
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ActionSignature;
