import React from "react";
import { createContext } from "react";

export const GlobalContext = createContext({});

/**
 *
 * @param {*} param0
 * @returns <GlobalContext.Provider/>
 */
function GlobalContextProvider({ children }) {
  const [open, setOpen] = React.useState(false);

  const contexts = {
    open,
    handleDrawerClose() {
      setOpen(false);
    },
    handleDrawerOpen() {
      setOpen(true);
    },
    handleDrawer() {
      setOpen(!open);
    },
  };

  return (
    <GlobalContext.Provider value={contexts}>{children}</GlobalContext.Provider>
  );
}

export default GlobalContextProvider;
