import { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from "@mui/material";
import { RequestHelper } from "../../utils/requestHelper";
import {
  _ACTIONS,
  _COLORS,
  _MESSAGES,
  _SHORTMESSAGES,
  _SWAL_ICONS,
} from "../../utils/_constants";
import { _STYLES } from "../../utils/_style_constants";

/**
 *
 * @returns <ResetPassword/>
 */
function ResetPassword() {
  const navigate = useNavigate();
  /* Récupère les variableS  */

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    formState = { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  /* Récupère le token dans l'url */
  const { token } = useParams();

  /* Vérifie si les mots de passe correspondent */
  const [passwordMatch, setPasswordMatch] = useState(true);
  const handlePasswordChange = () => {
    const password = watch("password");
    const confirmPassword = watch("confirmPassword");
    setPasswordMatch(password === confirmPassword);
  };

  /* Vérification des règles de création de mot de passe */
  const regexUppercase = /[A-Z]/;
  const regexSpecialCharacter = /[\W_]/;
  const validatePassword = (value) => {
    if (value.length < 8) {
      return "Le mot de passe doit contenir au moins 8 caractères";
    }

    if (!regexUppercase.test(value)) {
      return "Le mot de passe doit contenir au moins une majuscule";
    }

    if (!regexSpecialCharacter.test(value)) {
      return "Le mot de passe doit contenir au moins un caractère spécial";
    }

    return true;
  };

  /* Soumission du formulaire */
  const onSubmit = (data) => {
    data = {
      password: data.password,
      token: token,
    };
    const requestHelper = new RequestHelper();
    requestHelper
      .doRequest(_ACTIONS.POST, "/api/change-password", data)
      .then((response) => {
        if (response.data.code === 200) {
          Swal.fire({
            title: _SHORTMESSAGES.success,
            text: _MESSAGES.success,
            icon: _SWAL_ICONS.success,
          });
          navigate("/");
        } else {
          Swal.fire({
            icon: _SWAL_ICONS.error,
            title: _SHORTMESSAGES.error,
            text: response.data.message,
          });
        }
      });
  };
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src="../file/img/logo.png" alt="" />
          <h3>Rénitialiser votre mot de passe</h3>
          <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: 400 }}>
            <TextField
              sx={{ mt: 2, maxWidth: 400 }}
              fullWidth
              label={"Saisissez votre mot de passe"}
              {...register("password", {
                required: true,
                validate: validatePassword,
              })}
              type="password"
              onChange={() => handlePasswordChange()}
              helperText={
                <Box>
                  {errors.password?.type === "validate" && (
                    <Typography sx={_STYLES.errorMessage}>
                      Veuillez saisir un mot de passe contenant au moins 8
                      caractères, une majuscule, un caractère spécial
                    </Typography>
                  )}
                </Box>
              }
            />
            <TextField
              sx={{ mt: 2, maxWidth: 400 }}
              fullWidth
              label="Confirmer votre mot de passe"
              {...register("confirmPassword", {
                required: true,
                validate: (value) => value === watch("password"),
              })}
              type="password"
              helperText={
                <Box>
                  {errors.confirmPassword?.type === "validate" && (
                    <Typography sx={_STYLES.errorMessage}>
                      Veuillez saisir des mots de passe identiques
                    </Typography>
                  )}
                </Box>
              }
              onChange={() => handlePasswordChange()}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{ mt: 2, backgroundColor: _COLORS.primary }}
                variant="contained"
                type="submit"
              >
                Modifier
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </>
  );
}

export default ResetPassword;
