import Swal from "sweetalert2";

/**
 * Use to create toats
 * icon { warning, success, error, question }
 *
 * @param icon
 * @param message
 * @param time
 */
export async function createToast(icon, message, time = 2000) {
  let options = {
    toast: true,
    position: "top-end",
    icon: icon,
    title: message,
    showConfirmButton: false,
    didOpen(toast) {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  };

  if (time !== -1) {
    options.timer = time;
    options.timerProgressBar = true;
  }

  const Toast = Swal.mixin(options);

  await Toast.fire({}).then((r) => {
    return true;
  });
}
