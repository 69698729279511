import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./components/screens/Login";
import Nav from "./components/screens/Nav";
import ResetPassword from "./components/screens/ResetPassword";
import ListeAgences from "./components/screens/ListAgence";
import ListeUser from "./components/screens/ListUser";
import ListeMandats from "./components/screens/ListMandats";
import MandatVente from "./components/screens/MandatVente";
import Dashboard from "./components/screens/Dashboard";
import MandatRecherche from "./components/screens/MandatRecherche";
import Avenant from "./components/screens/Avenant";
import EnvoiSignature from "./components/screens/EnvoiSignature";
import { ListSignRequest } from "./components/screens/ListSignRequest";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/nav",
    element: <Nav content={<Dashboard />} />,
  },
  {
    path: "/reset-password/:token",
    element: <ResetPassword />,
  },
  {
    path: "/agences",
    element: <Nav content={<ListeAgences />} />,
  },
  {
    path: "/mandats",
    element: <Nav content={<ListeMandats />} />,
  },
  {
    path: "/users",
    element: <Nav content={<ListeUser />} />,
  },
  {
    path: "/mandat-vente/:id",
    element: <Nav content={<MandatVente />} />,
  },
  {
    path: "/mandat-recherche/:id",
    element: <Nav content={<MandatRecherche />} />,
  },
  {
    path: "/mandat-avenant/:id",
    element: <Nav content={<Avenant />} />,
  },
  {
    path: "/process-sign/:id",
    element: <Nav content={<EnvoiSignature />} />,
  },
  {
    path: "/list-sign-request",
    element: <Nav content={<ListSignRequest />} />,
  },
]);

export default function CustomRouter() {
  return <RouterProvider router={router} />;
}
