import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box } from "@mui/material";
import { _ACTIONS, _COLORS, _SWAL_ICONS } from "../../utils/_constants";
import { createConfirm } from "../../utils/confirm";
import { createToast } from "../../utils/toast";
import { downloadFile } from "../../utils/helper";

function SignRequestActions({
  row,
  requestHelper,
  setShowModal,
  setPdfURL,
  refresh,
  setRefresh,
}) {
  const cancelSignRequest = (row) => {
    createConfirm("Voulez-vous annuler la requête ?", _SWAL_ICONS.warning)
      .fire({})
      .then((result) => {
        if (result.isConfirmed) {
          requestHelper
            .doRequest(_ACTIONS.POST, `/api/cancel-sign-request`, {
              requestId: row.requestId,
            })
            .then((response) => {
              if (response.data.success) {
                createToast(_SWAL_ICONS.success, response.data.message).then();
              } else {
                createToast(_SWAL_ICONS.error, response.data.message).then();
              }
            });
        }
      });
  };

  const downloadSignDoc = (row) => {
    requestHelper
      .doRequest(_ACTIONS.POST, `/api/download-signed-doc`, {
        requestId: row.requestId,
      })
      .then((response) => {
        downloadFile("document.pdf", response.data.file);
      });
  };

  const viewSignDoc = (row) => {
    requestHelper
      .doRequest(_ACTIONS.POST, `/api/download-signed-doc`, {
        requestId: row.requestId,
      })
      .then((response) => {
        setPdfURL(response.fileURL);
        setShowModal(true);
      });
  };

  const refreshGrid = () => {
    setRefresh(!refresh);
  };

  const renderActionsCell = (row) => {
    switch (row.requestStatus) {
      // Status created
      case 0:
      case 3:
        return (
          <>
            <strong>
              <DoDisturbAltIcon
                titleAccess="Annuler la demande"
                sx={{ cursor: "pointer", color: _COLORS.primary }}
                onClick={() => cancelSignRequest(row)}
              ></DoDisturbAltIcon>
            </strong>
          </>
        );
      // Status signed
      case 4:
        return (
          <>
            <strong>
              <VisibilityIcon
                titleAccess="Voir le document signé"
                sx={{ cursor: "pointer", color: _COLORS.primary }}
                onClick={() => viewSignDoc(row)}
              ></VisibilityIcon>
              <DownloadIcon
                titleAccess="Télécharger le document signé"
                sx={{ cursor: "pointer", color: _COLORS.primary }}
                onClick={() => downloadSignDoc(row)}
              ></DownloadIcon>
            </strong>
          </>
        );
    }
  };

  return (
    <>
      <Box>{renderActionsCell(row)}</Box>
    </>
  );
}

export default SignRequestActions;
