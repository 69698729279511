import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import moment from "moment";
import { _STYLES, btn } from "../../utils/_style_constants";
import { RequestHelper } from "../../utils/requestHelper";
import { _ACTIONS, _COLORS, _FORMATS } from "../../utils/_constants";
import { downloadFile } from "../../utils/helper";
import { createToast } from "../../utils/toast";

import { UserContext } from "../contexts/UserContext";

export default function IpIsoleeForm({ showModal, setShowModal }) {
  const [typeVentes, setTypeVentes] = useState([]);
  const [typeVenteSelected, setTypeVenteSelected] = useState("");
  const [agences, setAgences] = useState([]);
  const [lieuDeVente, setLieuDeVente] = useState(null);
  const [exclusif, setExclusif] = useState("");
  const [selectedAgences, setSelectedAgences] = useState([]);
  const requestHelper = new RequestHelper();
  const { user } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /* GET Type de Vente Entity */
  useEffect(() => {
    getTypeDeVente();
  }, []);

  const getTypeDeVente = () => {
    requestHelper.get("/api/type_ventes").then((response) => {
      setTypeVentes(response.data["hydra:member"]);
    });
  };

  /* GET Agences Entity */
  useEffect(() => {
    getAgences();
  }, []);

  const getAgences = () => {
    setAgences(user.agences);
  };

  const onSubmit = (data) => {
    requestHelper
      .doRequest(_ACTIONS.POST, "/api/generate-ip-isolee", data)
      .then((response) => {
        if (response.data.success) {
          let now = moment();
          downloadFile(
            "ip_isolee_" + now.format(_FORMATS["fileName"]) + ".pdf",
            response.data.file
          );
          setTimeout(() => setShowModal(false), 500);
        } else {
          createToast("Oops ...", response.data.message);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="agencesLabel" size="small">
              Agences
            </InputLabel>
            <Select
              label="Agences"
              size="small"
              {...register("agences", { required: true })}
              value={selectedAgences}
              onChange={(e) => {
                setSelectedAgences(e.target.value);
              }}
            >
              {agences.map((agence, i) => (
                <MenuItem key={i} value={agence["id"]}>
                  {agence.nom}
                </MenuItem>
              ))}
            </Select>
            {errors.agence && (
              <Typography sx={_STYLES.errorMessage}>
                Veuillez séléctionnez une agence
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ display: "flex" }} fullWidth>
            <InputLabel id="typeVenteLabel" size="small">
              Type de vente
            </InputLabel>
            <Select
              label="Type de vente"
              size="small"
              value={typeVenteSelected}
              {...register("typeVente", { required: true })}
              id="typeVente"
              onChange={(e) => {
                setTypeVenteSelected(e.target.value);
              }}
            >
              {typeVentes.map((typeVente, i) => (
                <MenuItem key={i} value={typeVente["@id"]}>
                  {typeVente.nom}
                </MenuItem>
              ))}
            </Select>
            {errors.typeVente?.type === "required" && (
              <Typography sx={_STYLES.errorMessage}>
                Veuillez selectionner un type de vente
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel size="small">Exclusif</InputLabel>
            <Select
              label="Exclusif"
              size="small"
              {...register("exclusif", { required: true })}
              id="exclusif"
              value={exclusif}
              onChange={(e) => {
                setExclusif(e.target.value);
              }}
            >
              <MenuItem value={"exclusif"}>Exclusif</MenuItem>
              <MenuItem value={"non-exclusif"}>Non exclusif</MenuItem>
            </Select>
            {errors.exclusif?.type === "required" && (
              <Typography sx={_STYLES.errorMessage}>
                Veuillez selectionner une option
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel size="small">Lieu de vente</InputLabel>
            <Select
              label="Exclusif"
              size="small"
              {...register("lieuDeVente", { required: true })}
              id="lieuDeVente"
              value={lieuDeVente}
              onChange={(e) => {
                setLieuDeVente(e.target.value);
              }}
            >
              <MenuItem value={"auSeinEtablissement"}>
                Au sein de l'établissement
              </MenuItem>
              <MenuItem value={"horsEtablissement"}>
                Hors etablissement avec faculté de rétraction
              </MenuItem>
            </Select>
            {errors.lieuDeVente?.type === "required" && (
              <Typography sx={_STYLES.errorMessage}>
                Veuillez selectionner un lieu de vente
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} display={"flex"} justifyContent={"center"}>
          <Button
            type="submit"
            sx={btn(_COLORS.primary, _COLORS.primaryLight, "auto")}
            variant="contained"
          >
            Créer une IP isolée
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
