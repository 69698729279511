import {
  _NUE_PROPRIETE,
  _VENTE_A_TERME_LIBRE,
  _VENTE_A_TERME_OCCUPE,
  _VIAGER_COMPTANT,
  _VIAGER_LIBRE,
  _VIAGER_MIXTE,
  _VIAGER_OCCUPE,
} from "../../../utils/_constants";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import MouseOverPopover from "../MouseOverPopover";
import HelpIcon from "@mui/icons-material/Help";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { editInstantRequest } from "../../../utils/formHelper";
import { toolbarCkeditor } from "../../helpers/Utils";

function MandantDetailsAutre({
  currentMandat,
  setCurrentMandat,
  refresh,
  disabledSign = false,
  mandant,
}) {
  const handleChangeMandant = (value, f, mandant) => {
    setCurrentMandat({
      ...currentMandat,
      mandants: currentMandat.mandants.map((m) => {
        if (m["@id"] === mandant["@id"]) {
          m[f] = value;
        }
        return m;
      }),
    });

    editInstantRequest(value, mandant["@id"], f);
  };

  // True si nécessite d'afficher les cases à cocher
  const setCaseACocher =
    currentMandat?.typeVente?.nom === _VIAGER_OCCUPE ||
    currentMandat?.typeVente?.nom === _VIAGER_COMPTANT ||
    currentMandat?.typeVente?.nom === _NUE_PROPRIETE ||
    currentMandat?.typeVente?.nom === _VENTE_A_TERME_OCCUPE ||
    currentMandat?.typeVente?.nom === _VIAGER_MIXTE ||
    currentMandat?.typeVente?.nom === _VENTE_A_TERME_LIBRE ||
    currentMandat?.typeVente?.nom === _VIAGER_LIBRE;

  return (
    <>
      {/* PREMIERE LIGNE DE CASES A COCHER SUR LE TIERS AUTRE */}
      {setCaseACocher && (
        <Grid
          item
          xs={12}
          display={"row"}
          sx={{
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingLeft: "25px",
          }}
        >
          <Grid item xs={12} display={"flex"}>
            <FormControlLabel
              control={
                <Checkbox
                  id={"_occNonPropDomicileFamilial"}
                  checked={mandant?.occNonPropDomicileFamilial ? true : false}
                  disabled={currentMandat?.type === "avenant" || disabledSign}
                  onChange={(e) => {
                    handleChangeMandant(
                      e.target.checked ? true : false,
                      "occNonPropDomicileFamilial",
                      mandant
                    );
                    refresh();
                  }}
                />
              }
              label="Occupant non propriétaire du bien immobilier, mais y habitant au titre de son domicile familial"
              name="occNonPropDomicileFamilial"
              size="small"
            />
            <MouseOverPopover
              title={<HelpIcon />}
              popoverText={
                currentMandat?.typeVente?.nom === _VENTE_A_TERME_OCCUPE
                  ? "Exemple : Mme n’est pas propriétaire du bien, mais sera bénéficiaire de l’occupation."
                  : "Exemple : Mme n’est pas propriétaire du bien qui constitue son domicile familial ; elle doit donc intervenir à la signature du mandat de vente "
              }
            />
          </Grid>

          <Grid item xs={12} display={"flex"}>
            <FormControlLabel
              control={
                <Checkbox
                  id={"occNonPropConditionsVente"}
                  checked={mandant?.occNonPropConditionsVente ? true : false}
                  disabled={currentMandat?.type === "avenant" || disabledSign}
                  onChange={(e) => {
                    handleChangeMandant(
                      e.target.checked ? true : false,
                      "occNonPropConditionsVente",
                      mandant
                    );

                    refresh();
                  }}
                />
              }
              label={
                currentMandat?.typeVente?.nom === _VIAGER_LIBRE ||
                currentMandat?.typeVente?.nom === _VENTE_A_TERME_LIBRE
                  ? "Occupant non propriétaire du bien, mais bénéficiaire des conditions de la vente."
                  : "Occupant non propriétaire du bien, mais bénéficiaire des conditions de la vente (réversibilité de l’occupation et/ou du prix)."
              }
              name="occNonPropConditionsVente"
              size="small"
            />
            <MouseOverPopover
              title={<HelpIcon />}
              popoverText={
                currentMandat?.typeVente?.nom === _VENTE_A_TERME_OCCUPE
                  ? "Exemple : Mme n’est pas propriétaire du bien, mais sera bénéficiaire de l'occupation."
                  : currentMandat?.typeVente?.nom === _VENTE_A_TERME_LIBRE
                  ? "Exemple : Mme n’est pas propriétaire du bien, mais sera bénéficiaire de la mensualité."
                  : currentMandat?.typeVente?.nom === _VIAGER_MIXTE
                  ? "Exemple : Mme n’est pas propriétaire du bien, mais sera bénéficiaire du DUH (pour la partie occupée) et/ou de la rente viagère."
                  : currentMandat?.typeVente?.nom === _VIAGER_LIBRE
                  ? "Exemple : Mme n’est pas propriétaire du bien, mais sera bénéficiaire de la rente viagère."
                  : "Exemple : Mme n’est pas propriétaire du bien, mais sera bénéficiaire du DUH et/ou de la rente viagère"
              }
            />
          </Grid>
        </Grid>
      )}
      {/* DEUXIEME LIGNE DE CASE A COCHER SUR LE TIERS AUTRE */}
      {setCaseACocher && mandant?.occNonPropConditionsVente && (
        <Grid item xs={12} display={"row"}>
          {(currentMandat?.typeVente?.nom === _VIAGER_OCCUPE ||
            currentMandat?.typeVente?.nom === _VIAGER_MIXTE) && (
            <Grid item xs={10} sx={{ paddingLeft: "50px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={"reversibiliteRenteDuh"}
                    checked={mandant?.reversibiliteRenteDuh ? true : false}
                    disabled={currentMandat?.type === "avenant" || disabledSign}
                    onChange={(e) => {
                      handleChangeMandant(
                        e.target.checked ? true : false,
                        "reversibiliteRenteDuh",
                        mandant
                      );
                      refresh();
                    }}
                  />
                }
                label="Tiers bénéficiaire de la réversibilité de la rente viagère + DUH"
                name="reversibiliteRenteDuh"
                size="small"
              />
            </Grid>
          )}
          {currentMandat?.typeVente?.nom === _VIAGER_COMPTANT && (
            <Grid item xs={10} sx={{ paddingLeft: "50px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={"reversibiliteDuhIndemniteDepartAnticipe"}
                    checked={
                      mandant?.reversibiliteDuhIndemniteDepartAnticipe
                        ? true
                        : false
                    }
                    disabled={currentMandat?.type === "avenant" || disabledSign}
                    onChange={(e) => {
                      handleChangeMandant(
                        e.target.checked ? true : false,
                        "reversibiliteDuhIndemniteDepartAnticipe",
                        mandant
                      );
                      refresh();
                    }}
                  />
                }
                label="Tiers bénéficiaire de la réversibilite du DUH + de l'indemnite si départ anticipé"
                name="reversibiliteDuhIndemniteDepartAnticipe"
                size="small"
              />
            </Grid>
          )}
          {currentMandat?.typeVente?.nom === _VIAGER_COMPTANT && (
            <Grid item xs={10} sx={{ paddingLeft: "50px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={"reversibiliteIndemniteDepartAnticipe"}
                    checked={
                      mandant?.reversibiliteIndemniteDepartAnticipe
                        ? true
                        : false
                    }
                    disabled={currentMandat?.type === "avenant" || disabledSign}
                    onChange={(e) => {
                      handleChangeMandant(
                        e.target.checked ? true : false,
                        "reversibiliteIndemniteDepartAnticipe",
                        mandant
                      );
                      refresh();
                    }}
                  />
                }
                label="Tiers bénéficiaire de la réversibilite de l'indemnite si départ anticipé"
                name="reversibiliteIndemniteDepartAnticipe"
                size="small"
              />
            </Grid>
          )}
          {currentMandat?.typeVente?.nom === _NUE_PROPRIETE && (
            <Grid item xs={10} sx={{ paddingLeft: "50px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={"reversibiliteUsufruit"}
                    checked={mandant?.reversibiliteUsufruit ? true : false}
                    disabled={currentMandat?.type === "avenant" || disabledSign}
                    onChange={(e) => {
                      handleChangeMandant(
                        e.target.checked ? true : false,
                        "reversibiliteUsufruit",
                        mandant
                      );
                      refresh();
                    }}
                  />
                }
                label="Tiers bénéficiaire de la réversibilite de l’usufruit"
                name="reversibiliteUsufruit"
                size="small"
              />
            </Grid>
          )}
          {(currentMandat?.typeVente?.nom === _VIAGER_OCCUPE ||
            currentMandat?.typeVente?.nom === _VIAGER_MIXTE ||
            currentMandat?.typeVente?.nom === _VIAGER_LIBRE) && (
            <Grid item xs={10} sx={{ paddingLeft: "50px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={"reversibiliteRente"}
                    checked={mandant?.reversibiliteRente ? true : false}
                    disabled={currentMandat?.type === "avenant" || disabledSign}
                    onChange={(e) => {
                      handleChangeMandant(
                        e.target.checked ? true : false,
                        "reversibiliteRente",
                        mandant
                      );
                      refresh();
                    }}
                  />
                }
                label="Tiers bénéficiaire de la réversibilité de la rente viagère"
                name="reversibiliteRente"
                size="small"
              />
            </Grid>
          )}
          {currentMandat?.typeVente?.nom === _VENTE_A_TERME_OCCUPE && (
            <Grid item xs={10} sx={{ paddingLeft: "50px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={"reversibiliteOccupation"}
                    checked={mandant?.reversibiliteOccupation ? true : false}
                    disabled={currentMandat?.type === "avenant" || disabledSign}
                    onChange={(e) => {
                      handleChangeMandant(
                        e.target.checked ? true : false,
                        "reversibiliteOccupation",
                        mandant
                      );
                      refresh();
                    }}
                  />
                }
                label="Tiers bénéficiaire de la réversibilité de l'occupation'"
                name="reversibiliteOccupation"
                size="small"
              />
            </Grid>
          )}
          {currentMandat?.typeVente?.nom === _VENTE_A_TERME_LIBRE && (
            <Grid item xs={10} sx={{ paddingLeft: "50px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={"reversibiliteMensualite"}
                    checked={mandant?.reversibiliteMensualite ? true : false}
                    disabled={currentMandat?.type === "avenant" || disabledSign}
                    onChange={(e) => {
                      handleChangeMandant(
                        e.target.checked ? true : false,
                        "reversibiliteMensualite",
                        mandant
                      );
                      refresh();
                    }}
                  />
                }
                label="Tiers bénéficiaire de la réversibilité de la mensualité'"
                name="reversibiliteMensualite"
                size="small"
              />
            </Grid>
          )}
          {(currentMandat?.typeVente?.nom === _VIAGER_OCCUPE ||
            currentMandat?.typeVente?.nom === _VIAGER_MIXTE ||
            currentMandat?.typeVente?.nom === _VIAGER_COMPTANT) && (
            <Grid item xs={10} sx={{ paddingLeft: "50px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={"reversibiliteDroitUsage"}
                    checked={mandant?.reversibiliteDroitUsage ? true : false}
                    disabled={currentMandat?.type === "avenant" || disabledSign}
                    onChange={(e) => {
                      handleChangeMandant(
                        e.target.checked ? true : false,
                        "reversibiliteDroitUsage",
                        mandant
                      );
                      refresh();
                    }}
                  />
                }
                label="Tiers bénéficiaire de la réversibilité du droit d’usage et d’habitation"
                name="reversibiliteDroitUsage"
                size="small"
              />
            </Grid>
          )}
          <Grid item xs={2} sx={{ paddingLeft: "50px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  id={"reversibiliteAutre"}
                  checked={mandant?.reversibiliteAutre ? true : false}
                  disabled={currentMandat?.type === "avenant" || disabledSign}
                  onChange={(e) => {
                    handleChangeMandant(
                      e.target.checked ? true : false,
                      "reversibiliteAutre",
                      mandant
                    );
                    refresh();
                  }}
                />
              }
              label="Autres"
              name="reversibiliteAutre"
              size="small"
            />
          </Grid>
          {mandant?.reversibiliteAutre && (
            <Grid
              item
              xs={12}
              sx={{
                paddingLeft: "50px",
                paddingRight: "50px",
                paddingBottom: "10px",
              }}
            >
              <CKEditor
                editor={ClassicEditor}
                config={toolbarCkeditor}
                label="Autre clauses"
                disabled={currentMandat?.type === "avenant" || disabledSign}
                data={
                  mandant?.reversibiliteAutreText
                    ? mandant.reversibiliteAutreText
                    : ""
                }
                onBlur={(event, editor) => {
                  const data = editor.getData();
                  handleChangeMandant(data, "reversibiliteAutreText", mandant);
                  refresh();
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

export default MandantDetailsAutre;
