import _ from "lodash";
import Swal from "sweetalert2";
import { _MESSAGES } from "./_constants";

/**
 * Use to download file
 * @param {*} name
 * @param {*} url
 */
export function downloadFile(name, url) {
  url = url.includes("public/")
      ? "/" + url.split("public/")[1]
      : url;
  const link = document.createElement("a");
  link.setAttribute("download", name);
  link.target = "_blank";
  link.href = process.env.REACT_APP_downDir + url;
  document.body.appendChild(link);
  link.click();
  link.remove();
}
 
/**
 * Display In developpement message
 * @returns
 */
export function inDevInfo() {
  return Swal.fire({
    icon: "info",
    title: "Oops...",
    text: _MESSAGES.indev,
  });
}

/**
 *
 * @param {any} value
 * @returns
 */
export function is(value) {
  return !_.isNull(value) && !_.isUndefined(value);
}

/**
 * Permet de retourner un tableau des valeurs de chaque sous tableau
 *
 * @param array $collection
 * @param $valueKey {peut etre tout type de valeur}
 * @param bool $noOccurence
 * @return array
 */
export function getPropertyValueFromMultiArray(
  collection,
  valueKey,
  noOccurence = false
) {
  if (!collection) {
    return [];
  }
  let returnArray = [];
  collection.forEach((item) => {
    if (noOccurence) {
      if (_.indexOf(returnArray, item[valueKey]) === -1) {
        returnArray.push(item[valueKey]);
      }
    } else {
      returnArray.push(item[valueKey]);
    }
  });

  return returnArray;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
