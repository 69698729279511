import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { UserContext } from "../contexts/UserContext";
import { RequestHelper } from "../../utils/requestHelper";
import { Navigate, useNavigate } from "react-router";
import { Logout } from "@mui/icons-material";
import { _ACTIONS, _COLORS, _SWAL_ICONS } from "../../utils/_constants";
import { deleteCookie, removeCookie } from "../../utils/cookieHelper";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { createToast } from "../../utils/toast";
import _ from "lodash";

/**
 * @returns <Topbar/>
 */
function Topbar() {
  const { open, handleDrawerOpen } = useContext(GlobalContext);
  const navigate = useNavigate();

  /* Récupération des données de l'utilisateur dans l'app */
  const { user, updateUser, token, updateToken } = useContext(UserContext);

  /* Déconnexion utilisateur */
  const requestHelper = new RequestHelper(token);
  const handleLogout = () => {
    requestHelper.doRequest(_ACTIONS.POST, "/api/logout").then((response) => {
      if (response.status === 200) {
        updateUser(null);
        updateToken(null);
        delete sessionStorage.user;
        removeCookie("token");
        removeCookie("BEARER");
        navigate("/");
      }
    });
  };

  /**
   * Permet de vérifier si l'utilisateur est connecté sinon redirection vers la page de connexion
   */
  useEffect(() => {
    if (_.isEmpty(user) && sessionStorage.user === undefined) {
      Swal.fire({
        icon: _SWAL_ICONS.error,
        title: "Oops...",
        text: "Vous n'êtes pas connecté !",
      }).then(() => {
        navigate("/");
      });
    }

    if (user.firstConnexion === true) {
      createToast();
    }
  });

  /* Menu déroulant */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        sx={{ bgcolor: "white", color: "black" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              color: "black",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <img
                src={process.env.REACT_APP_baseUrl + "/file/img/logo.png"}
                className={`logo ${open ? "open" : ""}`}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
                padding: 0,
              }}
            >
              <Button>
                <Icon>
                  <NotificationsActiveIcon sx={{ color: _COLORS.light }} />
                </Icon>
              </Button>
              <Divider orientation="vertical" flexItem />
              <Button
                id="basic-button"
                aria-controls={openMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
                onClick={handleClick}
                sx={{ color: _COLORS.light }}
              >
                <Avatar
                  sx={{ marginRight: "8px", width: "25px", height: "25px" }}
                />
                <Box display={"flex"} sx={{ "flex-direction": "column" }}>
                  <Typography>
                    {" "}
                    {user.prenom} {user.nom}
                  </Typography>
                </Box>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Déconnexion
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Topbar;
