import React, { useState, useEffect, useNavigate } from "react";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CreationMandatVente from "../forms/mandats/CreationMandatVente";
import Mandant from "../forms/mandats/Mandant";
import { useForm } from "react-hook-form";
import { RequestHelper } from "../../utils/requestHelper";
import {
  _ACTIONS,
  _COLORS,
  _EN_COURS_SIGNATURE,
  _SIGNE,
} from "../../utils/_constants";
import { _STYLES } from "../../utils/_style_constants";
import { useParams } from "react-router";
import DetailRecherche from "../forms/mandats/DetailRecherche";
import RemunerationMandataire from "../forms/mandats/RemunerationMandataire";
import ConditionsParticulieres from "../forms/mandats/ConditionsParticulieres";
import BoxActionMandat from "../forms/mandats/BoxActionMandat";
import { editInstantRequest } from "../../utils/formHelper";
import { UserContext } from "../contexts/UserContext";

function MandatRecherche() {
  const { user, userAgences } = React.useContext(UserContext);
  const [currentMandat, setCurrentMandat] = useState([]);
  const [typeMandants, setTypeMandants] = useState([]);
  const [civilites, setCivilites] = useState([]);
  const [refreshMandat, setRefreshMandat] = useState(0);
  const [typeVentes, setTypeVentes] = useState([]);
  const [users, setUsers] = useState([]);
  const currentIdUrl = useParams();

  const requestHelper = new RequestHelper();

  const disabledSign =
    currentMandat?.statut?.nom === _SIGNE ||
    currentMandat?.statut?.nom === _EN_COURS_SIGNATURE;

  const mandantListNonSignataire = currentMandat?.mandants?.filter(
    (mandants) =>
      mandants.onlyForSignature === false || mandants.onlyForSignature === null
  );

  /* GET User Entity */
  useEffect(() => {
    getCivilite();
    getTypeMandant();
    getTypeVente();
    getUsers();
  }, []);

  useEffect(() => {
    /* GET Mandat by Id */
    getMandatById();
    getUsers();
  }, [refreshMandat]);

  const getTypeMandant = () => {
    requestHelper.get("/api/type_mandants").then((response) => {
      setTypeMandants(response.data["hydra:member"]);
    });
  };

  const getCivilite = () => {
    requestHelper.get("/api/civilites").then((response) => {
      setCivilites(response.data["hydra:member"]);
    });
  };

  const getTypeVente = () => {
    requestHelper.get("/api/type_ventes").then((response) => {
      setTypeVentes(response.data["hydra:member"]);
    });
  };

  const getMandatById = () => {
    requestHelper
      .doRequest(_ACTIONS.GET, `/api/mandats/${currentIdUrl.id}`)
      .then((response) => {
        if (response.status === 200) {
          setCurrentMandat(response.data);
        }
      });
  };

  const getUsers = () => {
    // on récupére l'agence du mandat
    let agenceAPi = currentMandat?.agence ? currentMandat.agence.id : "";
    // si le mandat est null, on récupére l'agence de l'utilisateur
    if (agenceAPi === "") {
      agenceAPi = user.agencesList;
      // on supprime la derniére virgule
      if (agenceAPi && agenceAPi[agenceAPi.length - 1] === ",") {
        agenceAPi = agenceAPi.slice(0, -1);
      }
    }
    requestHelper
      .get("/api/users?agences[]=" + agenceAPi + "&represent=true&actif=true")
      .then((response) => {
        setUsers(response.data["hydra:member"]);
      });
  };

  const {
    register,
    formState: { errors },
  } = useForm({ mode: "onChange	" });

  /** Fonction pour refresh le mandat  */
  const refresh = () => {
    setRefreshMandat(refreshMandat + 1);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        border: "2px solid rgb(238, 238, 238)",
        borderRadius: "10px",
        gap: "1rem",
        padding: 4,
        margin: 1,
      }}
    >
      <Grid sx={{ width: "100%" }}>
        <CreationMandatVente
          register={register}
          currentMandat={currentMandat}
          setCurrentMandat={setCurrentMandat}
          typeVentes={typeVentes}
          errors={errors}
          setTypeVentes={setTypeVentes}
          mode="recherche"
          refresh={refresh}
        />
      </Grid>
      <Grid sx={{ ..._STYLES.sxGridChild }}>
        <Mandant
          register={register}
          errors={errors}
          typeMandants={typeMandants}
          setTypeMandants={setTypeMandants}
          currentMandat={currentMandat}
          setCurrentMandat={setCurrentMandat}
          civilites={civilites}
          refresh={refresh}
          requestHelper={requestHelper}
          disabledSign={disabledSign}
          mandantListNonSignataire={mandantListNonSignataire}
        />
      </Grid>
      <Grid sx={{ ..._STYLES.sxGridChild }}>
        <DetailRecherche
          currentMandat={currentMandat}
          setCurrentMandat={setCurrentMandat}
          refresh={refresh}
          register={register}
          errors={errors}
          requestHelper={requestHelper}
        ></DetailRecherche>
      </Grid>
      <Grid sx={{ ..._STYLES.sxGridChild }}>
        <RemunerationMandataire
          currentMandat={currentMandat}
          setCurrentMandat={setCurrentMandat}
          refresh={refresh}
          register={register}
          field={"libre"}
        />
      </Grid>
      <Grid sx={{ ..._STYLES.sxGridChild }}>
        <ConditionsParticulieres
          currentMandat={currentMandat}
          setCurrentMandat={setCurrentMandat}
          refresh={refresh}
          register={register}
        />
      </Grid>
      <Grid sx={{ ..._STYLES.sxGridChild }}>
        <Box sx={{ flexGrow: 1, padding: 4 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bold",
                  color: _COLORS.primary,
                }}
              >
                Signatures
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", gap: 2 }}>
              <Typography>Signataire des mandataires</Typography>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <fieldset
                    disabled={currentMandat?.type === "avenant" || disabledSign}
                  >
                    <Select
                      sx={{ border: "none" }}
                      size="small"
                      {...register("signataire", { required: false })}
                      value={
                        currentMandat?.signataire
                          ? currentMandat?.signataire?.["@id"]
                          : ""
                      }
                      fullWidth
                      disabled={disabledSign}
                      onChange={(e) => {
                        let value =
                          e.target.value === "null" ? null : e.target.value;
                        let m = editInstantRequest(
                          value,
                          currentMandat["@id"],
                          "signataire"
                        );

                        m.then((m) => {
                          if (m["@context"] === "/api/contexts/Error") {
                            refresh();
                          } else {
                            setCurrentMandat(m);
                          }
                        });
                      }}
                    >
                      <MenuItem value="null">Aucun Signataire</MenuItem>
                      {users.map((u, i) => (
                        <MenuItem key={i} value={u["@id"]}>
                          {u.prenom + " " + u.nom}
                        </MenuItem>
                      ))}
                    </Select>
                  </fieldset>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <BoxActionMandat
        requestHelper={requestHelper}
        currentMandat={currentMandat}
        refresh={refresh}
      ></BoxActionMandat>
    </Grid>
  );
}

export default MandatRecherche;
